@import '../_variables';
@import '_mixins';

.chip {
  .text-yanone();
  text-transform: uppercase;
  color: @color-default-5;
  padding: 0 .75em 0 .75em; // padding 0 devido ao uppercase (nenhuma letra vai ter "perna" pra baixo)
  text-align: center;
  cursor: pointer;
  transition: background-color @transition-curve @transition-duration;
  user-select: none;
  display: inline-block;
  border-radius: @radius-default;
  border: 1px solid @color-default-5;
  height: 2rem;
  line-height: 2rem;

  &.chip-close {
    padding-right: 2.5rem;
    position: relative;

    &:before {
      content: '';
      width: 1.4em;
      height: 100%;
      background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 64 64'><path d='M34.962 32.5l11.113-11.2a2.394 2.394 0 0 0 0-3.36 2.35 2.35 0 0 0-3.336 0L31.985 28.775 21.232 17.942a2.344 2.344 0 0 0-3.335 0 2.444 2.444 0 0 0 0 3.36l11.122 11.2L17.905 43.7a2.367 2.367 0 0 0 3.335 3.36L32 36.228 42.749 47.06a2.344 2.344 0 0 0 3.335 0 2.444 2.444 0 0 0 0-3.36z' fill-rule='evenodd'/></svg>");
      background-size: 100%;
      background-position-y: center;
      position: absolute;
      right: 0;
      top: 0;
    }
  }

  &.compact {
    font-size: .9rem;
  }

  &.selected {
    background-color: @color-default-5;
    color: white;
  }

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      color: white;
      background-color: @color-accent-1;
      text-decoration: none;

      a {
        text-decoration: none;
      }
    }
  }

  &:active {
    background-color: @color-accent-2;
  }

  a {
    color: white;
  }
}