.fade-in {
  animation: fade forwards 3s;
}

.fade-out {
  animation: fade forwards reverse 3s;
}

@keyframes fade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}