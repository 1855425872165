@import 'variables';
@import 'mixins';

.cr-autocomplete {
  position: relative;

  &.loading:after {
    &:extend(.cr-spinner);
    .spinner-size(1rem);
    content: '';
    position: absolute;
    right: .5rem;
    top: 50%;
    animation-name: autocomplete-spinner-spin;
  }

  &:not(.loading):not(.no-right-icon) {

    &:after {
      .input-right-icon();
      background-color: @color-default-text-5;
      mask: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 64 64'><path d='M30.34 40a2.39 2.39 0 0 0 3.31 0l12.73-12.7a2.37 2.37 0 0 0 .69-1.66 2.34 2.34 0 0 0-.69-1.64 2.4 2.4 0 0 0-3.32 0L32 35 20.94 24a2.34 2.34 0 0 0-3.32 0 2.36 2.36 0 0 0 0 3.32z'/></svg>") no-repeat 50% 50%;
    }

    &:not(.cr-autocomplete-static):after {
      background-color: @color-default-text-5;
      mask: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 64 64'><path d='M50.91 48.6l-9.61-10 .52-.69a16.3 16.3 0 1 0-13 6.49 16.18 16.18 0 0 0 8.67-2.5l.69-.44 9.68 10.06a2.17 2.17 0 0 0 3 .07 2.14 2.14 0 0 0 .05-2.99zm-22.1-8.42a12.07 12.07 0 1 1 12.06-12.07 12.09 12.09 0 0 1-12.06 12.07z'/></svg>") no-repeat 50% 50%;
    }
  }

  select {
    display: none;
  }

  input {
    width: 100%;
    z-index: 1;
    padding-right: 2rem;
    padding-left: .5rem;
  }

  .cr-autocomplete-input-wrapper {
    position: relative;
  }

  input.input-sm + ul {
    top: @input-height-small-xs;

    @media (@min-screen-sm) {
      top: @input-height-small-sm;
    }
  }

  input.input-lg + ul {
    top: @input-height-large-xs;

    @media (@min-screen-sm) {
      top: @input-height-large-sm;
    }
  }

  input.input-xl + ul {
    top: @input-height-extra-large-xs;

    @media (@min-screen-sm) {
      top: @input-height-extra-large-sm;
    }
  }
}

@keyframes autocomplete-spinner-spin {
  0% {
    transform: translateY(-50%) rotate(0deg);
  }
  100% {
    transform: translateY(-50%) rotate(360deg);
  }
}