@import '../../submodules/src/styles/autocomplete';
@import '../../submodules/src/styles/alert';
@import '../../submodules/src/styles/tables';
@import 'element/_icons-mixins';
@import 'element/_image-pinch';
@import 'element/_chip';
@import 'element/_full-screen-image';
@import 'element/_accordion';
@import 'element/_fade';

.icon-reviews();
.icon-thumb();
.icon-like();
.icon-question-sign();
.icon-quality();
.icon-sizes();
.icon-photo();
.icon-pinch();
.icon-plus();
.icon-less();
.icon-zoom-out();
.icon-measurements();
.icon-arrow-down();
.icon-arrow-up();
.icon-useful();
.icon-star();
.icon-star-yellow();
.icon-star-half-yellow();
.icon-cashback();
.icon-combo();
.icon-ok();
.icon-combo-plus();

section#product {
    font-family: @roboto;

    #product-price-fixed {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100vw;
        min-height: 5rem;
        padding: 1rem 0 0 0;
        background-color: @color-white;
        display: flex;
        align-items: center;
        z-index: @header-z-index - 1;
        transition: bottom 0.5s ease-in-out;

        .content-price-fixed {
            width: 100vw;
        }

        .btn-xl {
            line-height: 3rem;
        }

        &.visible {
            bottom: 0;
        }

        #product-price-promo {
            display: none;
        }

        .h1,
        .product-price :first-child {
            margin-top: 0 !important;
        }

        .product-price-installments {
            display: none;
        }

        .product-price-warning {
            display: none;
        }

        .btn {
            width: auto;
            margin-left: auto;
        }
    }

    .btn {
        font-family: @roboto;
        border: 1px solid var(--color-default-3);
        font-weight: 400;
    }

    #product-about {
        margin-top: 1rem;

        @media (@max-screen-md) {
            margin-top: 0;
        }
    }

    #tableModeling {
        overflow: hidden;

        th {
            border-bottom: 0;
        }

        thead {
            background-color: transparent;

            th:not(:first-child) {
                position: relative;

                &:hover::after {
                    content: '';
                    position: absolute;
                    background-color: @size-table-gray-hover;
                    left: 0;
                    top: -1000px;
                    height: 2000px;
                    width: 100%;
                    z-index: -1;
                }
            }
        }

        tbody {
            td:not(:first-child),
            th {
                position: relative;

                &:hover::after {
                    content: '';
                    position: absolute;
                    background-color: @size-table-gray-hover;
                    left: 0;
                    top: -1000px;
                    height: 2000px;
                    width: 100%;
                    z-index: -1;
                }
            }

            tr {
                background-color: transparent;

                &:hover {
                    background-color: @size-table-gray-hover;
                }
            }
        }

        .title {
            width: 10%;
        }
    }

    #product-about-collapsible:not(.opened) {
        position: relative;
        max-height: 250px;

        &:after {
            content: '';
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            z-index: 1;
            position: absolute;
            background: linear-gradient(
                0deg,
                rgba(255, 255, 255, 1) 0%,
                rgba(255, 255, 255, 0) 50px
            );

            @media (@min-screen-xs) and (@max-screen-md) {
                background: linear-gradient(0deg, @color-default-1 0%, rgba(255, 255, 255, 0) 50px);
            }
        }
    }

    #product-campaign.d-flex {
        align-items: center;
        flex-direction: column;

        i#product-campaign-badge {
            background-image: url('@{cdn}/images/site/2021/camisetas-mudam-o-mundo/selo_CMM_preenchido_1e1e1d.svg?auto=compress,format&q=65');
            background-repeat: no-repeat;
            margin: 0 auto 1rem;
            width: 80px;
            height: 80px;
        }

        @media (@min-screen-xs) {
            &.d-flex {
                flex-direction: row;

                i#product-campaign-badge {
                    margin: auto 1rem auto 0;
                }
            }
        }
    }

    .hover-accordion {
        height: 30rem;
    }

    .hover-accordion-expand-first {
        &:not(:hover) .accordion-item-wrapper:first-child {
            flex-grow: 5;
        }

        .accordion-item-wrapper:hover {
            flex-grow: 5;
        }
    }

    .product-reviews-container {
        max-height: 30rem;
        overflow: auto;
        border: 2px solid @color-default-3;
        border-radius: 0.2rem;
        padding: 0 1rem;
        background-color: white;
    }

    .product-review {
        display: flex;
        padding: 1rem 0;

        .icon-cr {
            margin-right: 1rem;
            margin-top: 0.5rem;
        }

        &:not(:first-child) {
            border-top: 1px solid @color-default-2;
        }

        .product-review-info {
            color: @color-default-3;
        }
    }

    .product-review-reply {
        .product-review();
        margin-left: 1rem;

        &:not(:first-child) {
            border-top: none;
        }
    }

    #product-gallery {
        align-items: stretch;

        > :first-child {
            width: calc(100% - 13rem);
        }

        > :last-child {
            width: 13rem;
        }
    }

    .video-product {
        max-width: 50%;
        float: right;
        position: relative;
        margin-top: 1rem;

        .video-container {
            text-align: right;
        }

        iframe {
            max-width: 100%;
        }
    }

    @media (@max-screen-sm) {
        .video-product {
            position: relative;
            float: none !important;
            clear: both !important;
            width: 100% !important;
            height: 100% !important;
            max-width: none;

            .video-container {
                position: relative;
                width: 100% !important;

                iframe {
                    width: 100%;
                }
            }
        }
    }

    @media (@min-screen-md) {
        .video-product {
            padding-right: 15px;
        }
    }

    @media (@max-screen-md) {
        .productBuyInfos {
            padding-bottom: 0;
        }
    }
}

/* mobile */
@media (max-width: 565px) {
    /* 2 colunas */
    .product-list > * {
        flex: 0 0 50%;
    }
}

@media (min-width: 565px) and (max-width: 960px) {
    /* 3 colunas */
    .product-list > * {
        flex: 0 0 33.33%;
    }
}

/* desktop */
@media (min-width: 960px) {
    /* 5 colunas */
    .product-list > * {
        flex: 0 0 20%;
        padding-bottom: 0;
    }
}

// <editor-fold desc="QUALITY SECTION">

.hover-figure {
    position: relative;
    display: inline-block;
    overflow: hidden;

    figcaption {
        .text-ellipsis();
        position: absolute;
        padding: 0.25em;
        bottom: -0.2em;
        left: 0;
        right: 0;
        text-transform: uppercase;
        text-align: center;
        color: white;
        opacity: 1;
        transition: all 0.2s ease-in-out;
        background-color: @color-default-5;

        > * {
            margin: 0.5em 0;
        }
    }
}

@media (@min-screen-md) {
    .hover-figure {
        &:after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            opacity: 0.3;
            background-color: @image-overlay-color;
            transition: opacity 0.2s @transition-curve;
        }

        figcaption {
            opacity: 0;
        }
    }

    :not(.hover-accordion-keep-state) .hover-figure:hover,
    .hover-accordion.hover-accordion-keep-state > .accordion-item-wrapper.keep-state .hover-figure {
        &:after {
            opacity: 0;
        }

        figcaption {
            bottom: 0;
            opacity: @image-overlay-opacity;
        }
    }
}

.creator {
    display: block;
    position: relative;
    flex: 0 0 20%;
    @media (@max-screen-md) {
        flex: 0 0 40%;
    }
}

.creator-line {
    width: 56px;
    height: 56px;
    align-items: center;

    img {
        border-radius: 50%;
    }

    h2 {
        font-size: 1.2rem;

        @media (@max-screen-xs) {
            font-size: 14px;
        }
    }
}

.creator-link {
    text-decoration: underline;
    width: 25%;
    align-content: center;
    text-align: right;

    @media (@max-screen-xs) {
        font-size: 12px;
    }
}

#product-credit.d-flex {
    align-items: center;
    flex-direction: column;

    p {
        margin: 0;
    }

    .product-credit-img {
        width: 60px;
        height: 60px;
        margin: auto 1rem auto 0;
        border-radius: 50%;
    }

    .product-credit-text {
        align-self: center;
    }

    @media (@min-screen-xs) {
        &.d-flex {
            flex-direction: row;

            .product-credit-img {
                margin: auto 1rem auto 0;
            }
        }
    }

    @media (@min-screen-sm) and (@max-screen-md) {
        &.d-flex {
            margin: 1rem 0;
            padding: 0 14px;
        }
    }

    @media (max-width: 767px) {
        &.d-flex {
            justify-content: center;
        }
    }
}

.select-model-group {
    min-width: 250px;
    width: 70%;

    @media (@min-screen-sm) {
        width: 50%;
    }

    .select-model {
        padding: 0 10px !important;
        border: 1px solid;

        option {
            background-color: var(--color-light-shade-gray);
        }
    }

    &.cr-select::after {
        background-color: #fff !important;
    }
}

.price-mobile {
    float: left;
}

.price-old-mobile {
    padding-top: 2px;
}

.installment-mobile {
    font-size: 0.89rem;
    margin-top: 0.2rem;
    padding: 0 0 0 1.5rem;
}

.shipping-form {
    width: 80%;
    @media (@max-screen-sm) {
        width: 100%;
    }
}

.product-shipping-table {
    th,
    td {
        text-align: left;
    }

    @media (@max-screen-sm) {
        td:first-child {
            width: 28%;
        }

        td:last-child {
            width: 28%;
        }
    }

    @media (@min-screen-sm) {
        td:first-child {
            width: 24%;
        }
    }

    th:last-child,
    td:last-child {
        text-align: right;
    }

    label {
        margin: 0;
    }
}

.list-size-table {
    gap: 1rem;
}

.info-size-table {
    flex-direction: column;
    gap: 1rem;
}

.cotton {
    display: flex;
    @media (@max-screen-md) {
        display: none !important;
    }
}

.list-icons {
    width: 2rem;
    height: 2rem;
    flex-basis: 2rem;
    @media (@max-screen-md) {
        color: white;
    }
}

.link-size-guide {
    @media (@max-screen-md) {
        color: white !important;
    }
}

// </editor-fold>

// <editor-fold desc="Mobile slider">

.slider-bullets-wrapper {
    position: absolute;
    bottom: 1rem;
    left: 1.5rem;
    display: flex;
    gap: 0.4vw;
    flex-flow: wrap;
}

.mobile-slider-bullet {
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    margin-bottom: 0.25rem;
    margin-right: 0.25rem;
}

// </editor-fold>

#size-table-gray {
    padding: 0 !important;

    #sizes-table-content {
        flex-direction: column;

        @media (@min-screen-md) {
            flex-direction: row;
            justify-content: space-between;
            gap: 2rem;
        }
        .btn-title-sizes{
            float: left;
            font-size: 1.14em;
        }
    }

    #sizes-table-wrapper {
        flex-direction: column;
        align-items: center;
        gap: 1rem;

        @media (@min-screen-md) {
            align-items: flex-start;
        }

        .table-size-img-background {
            background-color: @new-grey;
            flex-direction: column;
            padding: 1rem;

            @media (@min-screen-md) {
                width: 50%;
                justify-content: space-between;
            }

            #img-wrapper {
                flex: 1;
                margin-bottom: 19px;
                flex-direction: column;
                justify-content: center;
            }
        }

        .size-table {
            flex-direction: column;
            padding: 2rem;
            gap: 2rem;

            @media (@min-screen-md) {
                padding: 0;
            }
        }
    }
}

// <editor-fold desc="Reviews">

.cr-review-note {
    font-family: @roboto;
    font-weight: 700;

    .note-crviews{
        .note-total{
            font-size: 2.6rem;
            float: left !important;
            margin-top: 3.5rem;
        }
    }
    .h1 {
        font-size:  5em;
        float: left !important;
        margin-bottom: 0;
        p {
            display: inline;
            font-size: 2.6rem;
            margin-left: -1.2rem;
        }
    }

    .h4 {
        font-size: 0.75em;
        font-style: normal;
        font-weight: 700;
    }

    span {
        color: @color-default-3;
        font-size: 0.75rem;
        margin-top: -1.5rem;
        margin-bottom: 3rem;
    }
}

.review-select {
    &::after{
        margin-top: -3rem !important;
    }
}

.cr-review {
    font-family: @roboto;

    hr {
        color: #797978;
        border: 1px solid;
        opacity: 0.2;
        margin-top: 0;
    }

    .h4 {
        font-weight: 600;
        font-size: 1.2em;
    }

    .review-info  {
        padding: 0 2rem 0 2rem;

        @media(@max-screen-sm){
            padding: 0 1rem 0 1rem;
        }

        margin-bottom: 2rem;

        .review-title{
            font-weight: bold;
            font-size: 1em;

            span {
                margin-bottom: 0.5rem;
            }
        }

        .review-text {
            font-weight: 400;
            font-size: 0.94em;
        }

        p{
            padding: 0 2rem 0 1rem;

            @media(@max-screen-sm){
                padding: 0 1rem 0 1rem;
            }
        }

        .review-reply {
            font-weight: 400;
            font-size: 0.94em;
            background-color: #F7F6F5;
            padding: 1rem 0 1rem 0.5rem;
            border-left: solid 1px;
        }
    }

    .review-date {
        font-weight: 400;
        font-size: 0.85em;
        color: #797978;
    }
}

.review-useful {
    font-weight: 400;
    font-size: 0.875rem;
    color: #797978;
    margin-left: 0.5rem;

    @media(@max-screen-sm){
        margin-bottom: 4px;
    }
}

.review-btn {
    padding: 2rem;

    @media(@max-screen-sm){
        padding: 1rem;
    }
}

#item-stars {
    .icon-cr {
        flex-basis: unset;
    }

    .icon-star,
    .icon-star-yellow,
    .icon-star-half-yellow {
        width: 25px;
        height: 25px;
    }

    .icon-star-yellow,
    .icon-star-half-yellow {
        color: #fbc13e;
    }

    .icon-star {
        color: #201f1e33;
    }
}
.review-statistics {
    hr {
        width: 195px;
    }
    h5 {
        font-weight: bold;
    }
    .binary-bar {
        text-transform: uppercase;
        font-size: 16px;
        font-weight: 700;
        gap: 10px;
        align-items: center;
        .binary-question-bar {
            display: flex;
            width: 308px;
            height: 33px;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            border: 2px solid @color-default-3;

            .binary-answer-bar {
                display: flex;
                padding: 0 16px;
                flex-direction: column;
                align-items: flex-end;
                line-height: 16.1px;
                background: rgba(121, 121, 120, 0.40);
            }
        }
    }

    .scale-bar {
        display: flex;
        width: 341px;
        height: 14px;
        padding-left: 0;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 10px;
        overflow: hidden;
        background: rgba(179, 181, 183, 0.60);
        .scale-answer-bar {
            display: flex;
            width: 40px;
            height: 14px;
            flex-direction: column;
            align-items: flex-end;
            flex-shrink: 0;
            background: @color-default-3;
        }
    }
    .scale-subtext {
        color: @color-default-3;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 16.1px; /* 134.167% */
        letter-spacing: 0.21px;
        text-transform: uppercase;
        max-width: 341px !important;
    }
}


.cashback-text {
    font-size: 12px;
    color: #0B6B07;
    .icon-cashback {
        width: 20px !important;
        height: 20px !important;
    }

    @media(max-width: 350px){
        padding-left: 3rem;
    }

}

.product-price-block {
    @media(@max-screen-xs) {
        justify-content: space-evenly;
    }
    @media(@max-screen-md) {
        padding: 0 !important;
    }
    @media(@min-screen-md){
        width: calc(50% - 0.5rem) !important;
        margin-left: 0.5rem !important;
    }
}
// </editor-fold>

.product-combo-desktop {
    display: flex;
}

.product-combo-item {
    position: relative;
    display: flex;

    background-color: #d9d9d9;

    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    &:before {
        content: '';
        padding-bottom: 144%;
    }
}
.bg-product-combo {
    background-color: #F7F6F5;
    padding: 0.5rem;
    max-width: 305px;
    align-self: center;
    text-align: center;
}

.bg-product-combo-mobile {
    background-color: #F7F6F5;
    padding: 0.5rem 1rem;
    align-self: center;
    text-align: center;
}

.product-combo-price p{
    margin: 0;
}

.combo-price-old {
    font-weight: 300;
    font-size: 1rem;
}

.combo-price {
    color: #e5702c;
    font-weight: bolder;
    line-height: 36px;
    font-size: 24px;
}

p.text-small {
    font-size: 0.7rem !important;
    margin: 0;
}

.product-combo-cashback {
    color: #0b6b07;
}

.icon-cr.icon-combo{
    display: inline-flex;
    align-self: center;
    z-index: 5;
    padding: 1.4rem;
    right: -23px;
    border-radius: 50%;
    background-color: white;
}

.icon-cr.icon-combo.icon-combo-mobile{
    display: inline-flex;
    align-self: center;
    z-index: 5;
    padding: 1.4rem;
    right: 80px;
    bottom: -26px;
    border-radius: 50%;
    background-color: white;
}

.color-badge-desktop {
    width: 35px;
    height: 14px;
    opacity: 0;
}

.color-badge-mobile {
    width: 14px;
    height: 14px;
    opacity: 0;
}

.product-combo-select select{
    font-family: @font-proxima-nova;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 1rem;
}

//.product-combo-select .cr-select {
//    @media(@max-screen-xs) {
//        max-width: 85%;
//    }
//}

.product-combo-selector select{
    @media (@max-screen-sm) {
        padding-left: 2rem !important;
    }
}

.desktop-badge {
    position: absolute;
    top: 12px;
    right: 2rem;
}

.mobile-badge {
    position: absolute;
    top: 12px;
    left: 9px;
}

.product-combo-mobile {
    display: grid;
    gap: 1rem;
}

.product-combo-mobile-item {
    width: 100%;
    position: relative;
    display: flex;

    background-color: #d9d9d9;

    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    &:before {
        content: '';
        padding-bottom: 140%;
    }

    @media(@max-screen-xs) {
        width: 30% !important;
    }
}

.kit-basics {
    display: flex;
    justify-content: space-between;
    background-color: #F5F6FA;
    align-items: center;
    padding: 0 1rem;
    gap: 1rem;
    margin-bottom: 2rem;

    p {
        margin: 0;
    }

    .name-text {
        font-size: 1.2rem;
        font-weight: 700;

        .normal {
            font-size: 1rem;
            font-weight: 400;
            text-transform: none;
        }

        .orange-price {
            font-size: 1.2rem;
            font-weight: 700;
            color: var(--color-primary-5);
        }
    }

    .economize-text {
        font-size: 2.6rem;
        font-weight: 700;

        span {
            color: var(--color-primary-5);
        }
    }

    .product-variations-types {
        display: flex;
        gap: 2px;
    }
}

.kit-basics-mobile {
    background-color: #F5F6FA;
    padding: 2rem 1rem;
    gap: 1rem;
    display: flex;
    margin-bottom: 2rem;
    flex-direction: column;
    align-items: center;

    .name-text {
        font-size: 1rem;
        font-weight: 700;
    }

    .economize-text {
        font-size: 2.3rem;
        font-weight: 700;
        margin: 0;

        span {
            color: var(--color-primary-5);
        }
    }

    .select-variation {
        gap: 1rem;

        p {
            font-size: 1rem;
            font-weight: 400;

            span {
                color: var(--color-primary-5);
            }
        }

        .cr-select {
            max-width: 100% !important;
        }
    }
}

.icon-cr.icon-select{
    align-self: center;
    border: 2px solid;
    z-index: 5;
    width: 24px;
    height: 24px;
    right: 8px;
    top:8px;
    background-color: @color-default-3;
    color: white;

    @media(@max-screen-xs) {
        width: 16px;
        height: 16px;
        top: unset;
        bottom: 8px;
    }
}
.icon-cr.icon-select.selected{
    background-color: @color-default-5;
    color: white;
}

.icon-cr.icon-combo-plus {
    width: 4rem;
    height: 4rem;
    top: 28%;
    right: 20px;
    z-index: 15;
    background-color: white;
    border-radius: 40px;

    @media(@min-screen-sm) and (@max-screen-md) {
        width: 3rem;
        height: 3rem;
        top: 18%;
        right: 15px;
    }

    @media(@max-screen-xs){
        width: 3rem;
        height: 3rem;
        bottom: 12px;
        top: unset ;
        right: -31px;
        background-color: transparent;
    }
}
