@import '../_variables';

.hover-accordion {
  display: flex;

  > .accordion-item-wrapper {
    flex: 1 1 auto;
    transition: flex .5s @transition-curve;
    overflow: hidden;
    position: relative;
    border-right: 1px solid white;
    border-left: 1px solid white;

    > .accordion-item {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;

      img {
        object-fit: cover;
        height: 100%;
        width: 100%;
      }
    }
  }

  &:not(.hover-accordion-keep-state) > .accordion-item-wrapper:hover, // responde ao hover
  &.hover-accordion-keep-state > .accordion-item-wrapper.keep-state { // responde ao cr-components.js
    flex: 4 1 auto;
    border-left: none;
    border-right: none;
  }
}