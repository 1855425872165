.image-pinch {
  position: relative;

  .loading {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    visibility: hidden;
  }

  img {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-backface-visibility: hidden;
    will-change: transform;
  }

  &.loading {
    .cr-spinner {
      visibility: visible;
    }

    img {
      visibility: hidden;
    }
  }
}