@import '../_variables';
@import '_mixins';

@viewport-padding-xs: .5rem;
@viewport-padding-sm: 2rem;

#image-zoom {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 10;

  #image-zoom-overlay {
    background: black;
    opacity: 1 - @overlay-opacity;
    width: 100%;
    height: 100%;
  }

  #image-zoom-loading {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    > span {
      &:extend(.cr-spinner);
      .spinner-size(3rem, white);
    }
  }

  #image-zoom-previous,
  #image-zoom-next {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);

    .icon-cr {
      display: block;
      width: 3rem;
      height: 3rem;
    }
  }

  #image-zoom-previous {
    left: @viewport-padding-xs;

    @media (@min-screen-sm) {
      left: @viewport-padding-sm;
    }
  }

  #image-zoom-next {
    right: @viewport-padding-xs;

    @media (@min-screen-sm) {
      right: @viewport-padding-sm;
    }
  }

  #image-zoom-close {
    position: absolute;
    top: @viewport-padding-xs;
    left: 50%;
    transform: translateX(-50%);
    color: white;

    @media (@min-screen-sm) {
      top: @viewport-padding-sm;
    }
  }

  .image-zoom-item {
    opacity: 0;
    transition: opacity @transition-duration @transition-curve;

    img {
      position: absolute;
      left: 50%;
      top: 50%;
      max-width: 80vw;
      max-height: 80vh;
      transform: translate(-50%, -50%);
    }

    .image-zoom-item-info {
      position: absolute;
      left: 50%;
      bottom: @viewport-padding-xs;
      transform: translateX(-50%);

      @media (@min-screen-sm) {
        bottom: @viewport-padding-sm;
      }
    }

    &.active {
      opacity: 1;
    }
  }
}